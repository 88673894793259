/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
import HoursTransformer from "../../../themes/answers-hitchhiker-theme/static/js/hours/transformer";

//show holiday hours if there are any in the upcoming week
export function holidayHours(profile) {
  if (!profile.hours) {
    return null;
  } else {
    let holidays = ``;
    const formattedHours = HoursTransformer._formatHoursForAnswers(profile.hours, profile.timeZoneUtcOffset);
    for (const day of formattedHours) {
      if (day.dailyHolidayHours && day.dailyHolidayHours.date) {
        let holidayHours = day.dailyHolidayHours;
        // Need to restructure date string to avoid Javascript 1 day off parse curiosity
        // Explanation here: https://stackoverflow.com/a/31732581
        let holidayDate = new Date(holidayHours.date.replace(/-/g, '\/').replace(/T.+/, ''));
        if (holidayHours.isClosed) {
          //closed on the holiday
          if (holidays.length > 0) {
            holidays += ', '
          }
          holidays += `<span class="HoursInterval-day">${holidayDate.toLocaleDateString('en-US')}</span> - Closed`;
        } else if (holidayHours.intervals && holidayHours.intervals.length > 0) {
          //open on the holiday
          let startTime = this._getTimeString(holidayHours.intervals[0].start);
          let endTime = this._getTimeString(holidayHours.intervals[0].end);
          if (holidays.length > 0) {
            holidays += ', '
          }
          holidays += `<span class="HoursInterval-day">${holidayDate.toLocaleDateString('en-US')}</span> - ${startTime}-${endTime}`;
        }
      }
    }
    if (holidays != '') {
      holidays = `<span class="Hours-holidayHours">Holiday Hours: </span>` + holidays;
    }
    return holidays;
  }
}
